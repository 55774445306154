import { Component, HostListener } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { AppConst } from './app.constant';
import { LocalStorageKeys } from './app.localstoragekeys';
import { CommonApiService } from './service/api/common-api.servive';
import { TranslateSvc } from './shared/translator/translator.provider';
import { LocalStorageProvider } from './service/localstorage/localstorage.service';
import { Meta } from '@angular/platform-browser';

import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  currentLang: string;

  private user_id: string;
  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;
  public subscriptions: Subscription[] = [];
  public connectionStatusMessage: string;
  public connectionStatus: string;
  public connectionEvent: any;
  public disConnectionEvent: any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private translateSvc: TranslateSvc,
    private commonApiService: CommonApiService,
    private localStorageProvider: LocalStorageProvider,
    private meta: Meta,
  ) {
    this.user_id = this.localStorageProvider.getItem('USER_ID');
    this.currentLang = this.localStorageProvider.getItem(LocalStorageKeys.CURRENTLANG) ? this.localStorageProvider.getItem(LocalStorageKeys.CURRENTLANG) : 'de';
    this.localStorageProvider.setItem(LocalStorageKeys.CURRENTLANG, this.currentLang);
    this.translateSvc.setDefaultLanguage(this.currentLang);
    this.checkTradingProfileStatus();
  }

  ngOnInit() {
    console.log('setting meta tags', environment.meta)
    this.meta.updateTag({ name: 'title', content: environment.meta.title });
    this.meta.updateTag({ name: 'description', content: environment.meta.description });
  }

  /**
   * Method is responsible for checking Trading Profile Status
   * @memberof AppComponent
   */
  /* istanbul ignore next */
  checkTradingProfileStatus() {
    console.log('checking trading profile status')
    this.user_id = this.localStorageProvider.getItem('USER_ID');
    if (this.user_id) {
      this.commonApiService.getOnboardingStatus().subscribe((data) => {
        if (data.status === AppConst.HTTP_STATUS.OK) {
          /** Check Trading profile has info or not */
          if (data.data.tradingProfile) {
            this.localStorageProvider.setItem(LocalStorageKeys.TRADINGPROFILEHASINFO, true);
          } else {
            this.localStorageProvider.setItem(LocalStorageKeys.TRADINGPROFILEHASINFO, false);
            this.localStorageProvider.setItem(LocalStorageKeys.TRADINGPROFILEREFLINK, this.router.url);
          }
          if (!data.data.tradingProfile) {
            this.router.navigate(['/post-auth/add-company']);
          }
        } else {
          this.toastr.error('Error', '204');
          this.router.navigate(['/pre-auth/login']);
        }
      }, (err) => {
        if (err && err.error.message) { this.toastr.error(err.error.message, err); }
        this.router.navigate(['/pre-auth/login']);
      });
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // this.eventService.BroadcastEvent('ON_FOCUS', true);
  }

}
