import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConst } from 'src/app/app.constant';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    constructor(
        private httpClient: HttpClient
    ) { }

    /**
    * Method is responsible for send notification
    *
    * @returns {Observable<AddEntityApiRes>}
    * @memberof ErrorService
    */
    sendNotification(notificationData): Observable<any> {
        const request = {
            'url': AppConst.API_BASE_URL + 'v1/scheduler/sendSlackNotification',
            'params': notificationData
        };
        return this.httpClient.post<any>(request.url, request.params);
    }
}
