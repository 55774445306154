import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/service/api/error.service';
import { LocalStorageProvider } from '../service/localstorage/localstorage.service';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
    constructor(
        private errorService: ErrorService,
        private localStorageProvider: LocalStorageProvider,
    ) {

    }
    public previousError: string;
    /* istanbul ignore next */
    handleError(error: Error | HttpErrorResponse) {
        if (this.previousError !== error.toString()) {
            const deviceInfo = this.localStorageProvider.getItem('deviceInfo') ? JSON.parse(this.localStorageProvider.getItem('deviceInfo')) : {};
            this.previousError = error.toString();
            // Log the error anyway
            console.error(error);
            const data = {
                path: window.location.href,
                error: error.toString(),
                deviceInfo: deviceInfo
            };
            const reqData = {
                heading: 'Data Parsing Error In User Panel',
                data: JSON.stringify(data, null, 2)
            };
            this.errorService.sendNotification(reqData).subscribe((res) => {

            }, (err) => {

            });
        }
    }
}
