import { environment } from './environments/environment';


// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    'aws_project_region': environment.aws_project_region,
    'aws_cognito_region': environment.aws_cognito_region,
    'aws_user_pools_id': environment.aws_user_pools_id,
    'aws_user_pools_web_client_id': environment.aws_user_pools_web_client_id,
    'aws_app_analytics': environment.aws_app_analytics,
    'aws_cognito_identity_pool_id': environment.aws_cognito_identity_pool_id,
    'aws_mobile_analytics_app_id': environment.aws_mobile_analytics_app_id,
    'aws_mobile_analytics_app_region': environment.aws_mobile_analytics_app_region,
    'aws_user_pools': environment.aws_user_pools,
};

export default awsmobile;
