import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotFoundComponent } from './not-found/not-found.component';

const routeConfig: Routes = [
  { path: '', redirectTo: 'pre-auth', pathMatch: 'full' },
  { path: 'pre-auth', loadChildren: () => import('./modules/pre-auth/pre-auth.module').then(m => m.PreAuthModule) },
  { path: 'post-auth', loadChildren: () => import('./modules/post-auth/post-auth.module').then(m => m.PostAuthModule) },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routeConfig,
      {
        enableTracing: environment.production ? false : true,
      }
      // {
      //   preloadingStrategy: PreloadAllModules
      // }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
