import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ErrorsHandler } from './error-handler';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [

  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler,
    }
  ]
})
export class ErrorsModule { }
