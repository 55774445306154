import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TranslateSvc {

    constructor(
        private translate: TranslateService
    ) {

    }

    public applySelectedLanguage(lang: string) {
        return new Promise((resolve) => {
            this.translate.setDefaultLang(lang);
            this.translate.use(lang);
            resolve(null);
        });
    }

    public getTranalation(key: string, param?: object) {
        return new Promise((resolve, reject) => {
            this.translate.get(key, param).subscribe((res) => {
                resolve(res);
            });
        });
    }

    public setDefaultLanguage(lang: string) {
        this.translate.setDefaultLang(lang);
    }
}
