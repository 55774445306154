export const environment = {
  production: false,
  API_BASE_URL: 'https://8hgp57ao7i.execute-api.eu-central-1.amazonaws.com/dev/',
  API_SOCKET_URL: 'wss://esfi75eq44.execute-api.eu-central-1.amazonaws.com/dev?',
  aws_user_pools_id: 'eu-central-1_PnF99BD8z',
  aws_user_pools_web_client_id: '40glih2h8rugihnc0hebpimrg',
  idle_countdown_timer: 20,
  aws_project_region: 'eu-central-1',
  aws_cognito_region: 'eu-central-1',
  aws_app_analytics: 'enable',
  aws_cognito_identity_pool_id: 'eu-central-1:25be421a-7c99-4d83-b3fa-07efce2293f7',
  aws_mobile_analytics_app_id: 'e382e7e87aef4d7597689836ef857703',
  aws_mobile_analytics_app_region: 'us-east-1',
  aws_user_pools: 'enable',
  meta: {
    title: 'Unamera',
    description: 'Unamera'
  }
};
