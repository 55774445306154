import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import { Amplify, Analytics } from "aws-amplify";
import amplify from "./aws-exports";



Amplify.configure(amplify);
Analytics.enable();

// Amplify.Logger.LOG_LEVEL = 'DEBUG';
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error("error bootstraping app module err: ", err));
