export class LocalStorageKeys {
    public static readonly CURRENTLANG = 'currentLang';
    public static readonly REDIRECT_FROM_LOGIN = 'redirectFromLogin';
    public static readonly LIVE_DEMAND_INDEX = 'liveDemandIndex';
    public static readonly LIVE_OFFER_INDEX = 'liveOfferIndex';
    public static readonly LIVE_DEMAND_ACTIVE_TAB_INDEX = 'liveDemandActiveTabIndex';
    public static readonly SUGGESTION_ACTIVE_TAB_INDEX = 'suggestionActiveTabIndex';
    public static readonly CONTRACT_ACTIVE_TAB_INDEX = 'contractActiveTabIndex';
    public static readonly LIVE_OFFER_ACTIVE_TAB_INDEX = 'liveOfferActiveTabIndex';
    public static readonly SELECTED_MARKET_ID = 'selectedMarketId';
    public static readonly GROUPDETAILS = 'groupDetails';
    public static readonly TRADINGPROFILEHASINFO = 'tradingProfileHasInfo';
    public static readonly TRADINGPROFILEREFLINK = 'tradingProfileRefLink';
}
