import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { AppConst } from 'src/app/app.constant';
import { LocalStorageKeys } from 'src/app/app.localstoragekeys';
import { ErrorService } from 'src/app/service/api/error.service';
import { EventService } from 'src/app/service/event/event.service';
import { LocalStorageProvider } from '../localstorage/localstorage.service';

/**
 * @fileOverview This file is responsible for adding headers
 *  at the time of API calling. Works as an Interceptor.
 * @author TA
*/
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    counter = 0;
    constructor(
        private localStorageProvider: LocalStorageProvider,
        private eventService: EventService,
        private errorService: ErrorService,
        private route: Router,
    ) { }

    /* istanbul ignore next */
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // tslint:disable-next-line:max-line-length
        const token = this.localStorageProvider.getItem('USER_TOKEN') ? this.localStorageProvider.getItem('USER_TOKEN') : '';
        const apiRequest = req.clone({
            headers: req.headers.set('apikey', AppConst.API_KEY).set('accept-language', this.localStorageProvider.getItem(LocalStorageKeys.CURRENTLANG)).set('time-offset', new Date().getTimezoneOffset().toString()).set('token', token)
        });

        return next.handle(apiRequest).pipe(map((event: HttpEvent<any>) => {
            return event;
        }), tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if (event.status !== 200) {
                    this.eventService.BroadcastEvent('ERROR_OCCURED', true);
                }
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 403) {
                    console.log('err - ', err);
                    if (this.route.url.includes('pre-auth')) {
                        const tutorial_state = this.localStorageProvider.getItem('seeTutorialStatus');
                        const marketplace_tutorial_state = this.localStorageProvider.getItem('marketplaceSeeTutorialStatus');
                        const currentLang = this.localStorageProvider.getItem(LocalStorageKeys.CURRENTLANG);
                        this.localStorageProvider.clearAll();
                        this.eventService.BroadcastEvent('DISCONNECT_SOCKET', true);
                        this.localStorageProvider.setItem(LocalStorageKeys.CURRENTLANG, currentLang);
                        this.localStorageProvider.setItem('seeTutorialStatus', tutorial_state ? tutorial_state : 'false');
                        this.localStorageProvider.setItem('marketplaceSeeTutorialStatus', marketplace_tutorial_state ? marketplace_tutorial_state : 'false');
                        this.route.navigate(['/pre-auth/login']);
                    } else {
                        this.eventService.BroadcastEvent('LOG_OUT', true);
                    }
                } else if (err.status !== 400) {
                    if (!err.status && err.url && !err.url.includes('sendSlackNotification')) {
                        this.eventService.BroadcastEvent('ERROR_OCCURED', true);
                        const deviceInfo = this.localStorageProvider.getItem('deviceInfo') ? JSON.parse(this.localStorageProvider.getItem('deviceInfo')) : {};
                        const data = {
                            path: window.location.href,
                            error: err,
                            deviceInfo: deviceInfo
                        };
                        const reqData = {
                            heading: 'HTTP Error In User Panel',
                            data: JSON.stringify(data, null, 2)
                        };
                        this.errorService.sendNotification(reqData).subscribe((res) => {

                        }, (error) => {
                            console.log('error sending slack notification')
                        });
                        this.eventService.BroadcastEvent('ERROR_OCCURED', true);
                    }
                }
            }
        }))
    }
}
