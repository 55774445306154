import { HttpStatus } from './model/http';
import { environment } from 'src/environments/environment';

console.log('Using API: ', environment.API_BASE_URL)
export class AppConst {
    // encryption key
    public static readonly ENC_KEY = '!HOUSE@ofCrops';

    // API KEY
    public static readonly API_KEY = 'mDmMnUhF9zi8aSVAHJT8bIdvZE4Tmyqropi4YWD4';

    // numberValidation
    public static readonly NUMBER_WITH_TWO_DECIMAL = /^\s*(?=.*[1-9])\d*(?:(\.|\,)\d{1,2})?\s*$/;
    public static readonly NUMBER_WITH_ZERO_AND_TWO_DECIMAL = /^\s*(?=.*[0-9])\d*(?:(\.|\,)\d{1,2})?\s*$/;

    // API BASE URL
    public static readonly API_BASE_URL = environment.API_BASE_URL;
    // public static readonly API_BASE_URL = 'http://10.0.8.159:3000/'; // Arup
    // public static readonly API_BASE_URL = 'http://10.0.8.76:3000/'; //  Soma
    // public static readonly API_BASE_URL = 'http://localhost:3000/'; // Local system

    // APPLICATION BASE URL
    public static readonly APPLICATION_BASE_URL = window.location.origin + '/post-auth/';
    public static readonly APPLICATION_BASE_URL_DEMAND = window.location.origin + '/post-auth/viewdemand/';
    public static readonly APPLICATION_BASE_URL_OFFER = window.location.origin + '/post-auth/viewoffer/';
    // public static readonly APPLICATION_BASE_URL = 'https://dev.houseofcrops.de/post-auth/viewdemand/'; // dev

    public static readonly API_SOCKET_URL = environment.API_SOCKET_URL;

    // server HTTP header status code
    public static readonly HTTP_STATUS: HttpStatus = {
        OK: 200,
        SERVER_INTERNAL_ERROR: 500,
        FORBIDDEN: 401,
        IFNOTOK: 201,
        BAD_REQUEST: 400,
        NOT_FOUND: 404,
        NO_CONTENT: 204
    };

    // google map theme
    public static readonly mapThemeVersion = 1;
    public static readonly mapTheme = [
        [
            {
                'featureType': 'all',
                'elementType': 'labels.text.fill',
                'stylers': [
                    {
                        'saturation': 36
                    },
                    {
                        'color': '#000000'
                    },
                    {
                        'lightness': 40
                    }
                ]
            },
            {
                'featureType': 'all',
                'elementType': 'labels.text.stroke',
                'stylers': [
                    {
                        'visibility': 'on'
                    },
                    {
                        'color': '#000000'
                    },
                    {
                        'lightness': 16
                    }
                ]
            },
            {
                'featureType': 'all',
                'elementType': 'labels.icon',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'administrative',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'color': '#000000'
                    },
                    {
                        'lightness': 20
                    }
                ]
            },
            {
                'featureType': 'administrative',
                'elementType': 'geometry.stroke',
                'stylers': [
                    {
                        'color': '#000000'
                    },
                    {
                        'lightness': 17
                    },
                    {
                        'weight': 1.2
                    }
                ]
            },
            {
                'featureType': 'landscape',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#000000'
                    },
                    {
                        'lightness': 20
                    }
                ]
            },
            {
                'featureType': 'road.highway',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'color': '#000000'
                    },
                    {
                        'lightness': 17
                    }
                ]
            },
            {
                'featureType': 'road.highway',
                'elementType': 'geometry.stroke',
                'stylers': [
                    {
                        'color': '#000000'
                    },
                    {
                        'lightness': 29
                    },
                    {
                        'weight': 0.2
                    }
                ]
            },
            {
                'featureType': 'road.arterial',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#000000'
                    },
                    {
                        'lightness': 18
                    }
                ]
            },
            {
                'featureType': 'road.local',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#000000'
                    },
                    {
                        'lightness': 16
                    }
                ]
            },
            {
                'featureType': 'transit',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#000000'
                    },
                    {
                        'lightness': 19
                    }
                ]
            },
            {
                'featureType': 'water',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#000000'
                    },
                    {
                        'lightness': 17
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'all',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            }
        ],
        [

            {
                'featureType': 'landscape.man_made',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'visibility': 'on'
                    }
                ]
            },
            {
                'featureType': 'landscape.man_made',
                'elementType': 'labels.text.fill',
                'stylers': [
                    {
                        'visibility': 'on'
                    }
                ]
            },
            {
                'featureType': 'landscape.natural.landcover',
                'elementType': 'all',
                'stylers': [
                    {
                        'visibility': 'on'
                    }
                ]
            },
            {
                'featureType': 'landscape.natural.landcover',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'visibility': 'on'
                    }
                ]
            },
            {
                'featureType': 'landscape.natural.terrain',
                'elementType': 'all',
                'stylers': [
                    {
                        'visibility': 'on'
                    }
                ]
            },
            {
                'featureType': 'landscape.natural.terrain',
                'elementType': 'labels.text.fill',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'road.highway',
                'elementType': 'all',
                'stylers': [
                    {
                        'visibility': 'on'
                    }
                ]
            },
            {
                'featureType': 'road.highway',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'visibility': 'on'
                    }
                ]
            },
            {
                'featureType': 'road.highway',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'lightness': '43'
                    },
                    {
                        'saturation': '-25'
                    }
                ]
            },
            {
                'featureType': 'road.highway',
                'elementType': 'labels',
                'stylers': [
                    {
                        'visibility': 'on'
                    }
                ]
            },
            {
                'featureType': 'road.highway',
                'elementType': 'labels.text',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'road.highway',
                'elementType': 'labels.text.fill',
                'stylers': [
                    {
                        'visibility': 'on'
                    },
                    {
                        'saturation': '-100'
                    },
                    {
                        'lightness': '28'
                    }
                ]
            },
            {
                'featureType': 'road.highway',
                'elementType': 'labels.icon',
                'stylers': [
                    {
                        'visibility': 'on'
                    },
                    {
                        'weight': '0.50'
                    }
                ]
            },
            {
                'featureType': 'road.arterial',
                'elementType': 'labels',
                'stylers': [
                    {
                        'visibility': 'on'
                    }
                ]
            },
            {
                'featureType': 'water',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'visibility': 'on'
                    },
                    {
                        'color': '#47b7c5'
                    },
                    {
                        'lightness': '68'
                    },
                    {
                        'saturation': '13'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'all',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            }
        ]
    ];

    // google map API key
    public static readonly mapAPIKey = 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw';

    public static readonly introJSContent = {
        introduction: {
            en: 'This short step-by-step guide explains the negotiation functionalities in 6 quick steps. We recommend every user to read the instructions carefully.',
            de: 'Diese kurze Schritt-für-Schritt Anleitung erklärt Ihnen die Verhandlungsfunktionalitäten in 6 schnellen Schritten. Wir empfehlen jedem Nutzer, die Anleitung aufmerksam durchzugehen.'
        },
        step1: {
            en: 'The top section provides an overview of the current status of the negotiation.',
            de: 'Der obere Bereich bietet einen Überblick über den aktuellen Verhandlungsstand.'
        },
        step2: {
            en: 'In the right column of the page, an area is reserved for attachments, adittional notes and information concerning your trading partner.',
            de: 'In der rechten Spalte der Seite ist ein Bereich für Anhänge, zusätzliche Anmerkungen und Informationen zu Ihrem Handelspartner reserviert.'
        },
        step3: {
            // tslint:disable-next-line: max-line-length
            en: 'In the ‘Open Parameters section’ you can prepare an action by adding values into the free column and confirming these. When your value matches with that of your trading partner, it is transferred to the ‘Contract Parameters’ section below.',
            de: 'Im Bereich ‘Offene Parameter’ können Sie eine Aktion vorbereiten, indem Sie Werte in die freie Spalte einfügen und diese bestätigen. Stimmt der Wert mit ihrem Handelspartner überein, rutscht er in den Bereich ‘Kontraktparameter’.'
        },
        step4: {
            en: 'All remaining parameters are listed in the \'Contract Parameters\' area and are considered accepted.',
            de: 'In dem Bereich \'Kontraktparameter\' befinden sich alle weiteren Parameter und gelten als akzeptiert.'
        },
        step5: {
            // tslint:disable-next-line: max-line-length
            en: 'After you have worked through all open parameters, you can accept, decline or send a bid / offer using the blue action banner. To accept the bid / offer from your trading partner and conclude a contract, click the green button with the checkmark on the left. To send a counter bid / offer, make sure you have selected an action, an entity, and a period of validity. Once the \'SEND\' button is activated, you are able to send your counter bid/offer.',
            // tslint:disable-next-line: max-line-length
            de: 'Nachdem Sie alle offenen Parameter bestätigt haben, können Sie über den blauen Aktionsbalken ein Gebot / eine Offerte anehmen, ablehnen oder senden. Zum Annehmen, klicken Sie auf den linken grünen Button mit Häkchen. Um ein Gegenangebot zu schicken, wählen Sie eine Aktion, einen Betrieb und ein Gültigkeitsdatum aus. Wenn der \'SENDEN\' Knopf aktiviert ist, können Sie Ihr Gebot / Ihre Offerte versenden.'
        }

    };

    public static readonly marketPlaceIntroJSContent = {
        introduction: {
            en: 'The cropMATE algorithm evaluates all active listings based on your past activities and suggests suitable trading opportunities for you.',
            de: 'Der cropMATE-Algorithmus evaluiert alle aktiven Inserate basierend auf Ihren bisherigen Aktivitäten und schlägt Ihnen geeignete Handelsmöglichkeiten vor.'
        },
        step1: {
            en: 'Take a look at the key parameters of the trading opportunities...',
            de: 'Schauen Sie sich die Eckpunkte der Handelsmöglichkeiten an...'
        },
        step2: {
            en: '... and start a negotiation or mark the listing as not interesting.',
            de: '... und starten Sie eine Verhandlung oder markieren das Inserat als nicht interessant.'
        },
        step3: {
            // tslint:disable-next-line: max-line-length
            en: 'Click on \'Start/continue negotiation\' to access the negotiation page. A match will only be created if you then send an offer / bid. If you select \'Not interested\', the suggestion will be removed and a new suggestion will appear.',
            // tslint:disable-next-line: max-line-length
            de: 'Über \'Verhandlung starten/fortsetzen\' rufen Sie die Verhandlungsseite auf. Erst wenn Sie daraufhin eine Offerte/ein Gebot senden, entsteht ein Match. Wenn Sie \'Nicht interessiert\' auswählen, wird der Vorschlag entfernt und ein neuer Vorschlag erscheint.'
        }
    };

    /* tslint:disable */
    public static readonly negotiationContractParameters = [
        { key: 'qualityClass', name: 'KEY_REQUIREMENT.QUALITY_CLASS', masterDataKey: 'qualityClass', type: 'select', isYesNoData: false, validationType: '', isMandatory: false },
        { key: 'parity', name: 'KEY_REQUIREMENT.PARITY', masterDataKey: 'parity', type: 'select', isYesNoData: false, validationType: '', isMandatory: true },
        // area of delivery area of origin
        { key: 'placeOfFulfillment', name: 'LOCATION.PLACE_OF_FULFILMENT', masterDataKey: '', type: 'text', isYesNoData: false, validationType: '', isMandatory: true },
        { key: 'quantity', name: 'LIVE_DEMAND.QUANTITY_IN_METRIC_TON', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: true },
        { key: 'paymentDocument', name: 'LOGISTIC_HANDLING.PAYMENT_AGAINST_THE_FOLLOWING_DOCUMENTS', masterDataKey: '', type: 'text', isYesNoData: false, validationType: '', isMandatory: false },
        { key: 'deliveryPeriod', name: 'KEY_REQUIREMENT.DELIVERY_PERIOD', masterDataKey: '', type: 'daterange', isYesNoData: false, validationType: '', isMandatory: true },
        { key: 'deliveryPeriodWithoutGrace', name: 'KEY_REQUIREMENT.WITHOUT_GRACE_PERIOD', masterDataKey: '', type: 'select', isYesNoData: true, validationType: '', isMandatory: false },
        { key: 'storageFee', name: 'KEY_REQUIREMENT.STORAGE_FEES', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'storageFeeStartDate', name: 'KEY_REQUIREMENT.STORAGE_FEES_START_DATE', masterDataKey: '', type: 'date', isYesNoData: false, validationType: '', isMandatory: false },
        { key: 'baseMonth', name: 'KEY_REQUIREMENT.BASE', masterDataKey: '', type: 'date', isYesNoData: false, validationType: '', isMandatory: false },
        { key: 'priceType', name: 'PRICE.PRICE_TYPE', masterDataKey: 'priceTypeArr', type: 'select', isYesNoData: false, validationType: '', isMandatory: true },
        { key: 'flatPriceValue', name: 'PRICE.FLAT_PRICE_VALUE', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: true },
        { key: 'premiumPriceEURPerMetricTon', name: 'PRICE.PREMIUM_IN_EUR_PER_METRIC_TON', masterDataKey: 'premiumPriceEURPerMetricTonArr', type: 'select', isYesNoData: false, validationType: '', isMandatory: true },
        { key: 'premiumPriceForwardBaseMATIF', name: 'PRICE.FORWARD_BASE_MATIF', masterDataKey: 'premiumPriceForwardBaseMATIFArr', type: 'select', isYesNoData: false, validationType: '', isMandatory: true },
        { key: 'premiumPriceFixingTime', name: 'PRICE.FIXING_TIME_WINDOW', masterDataKey: '', type: 'daterange', isYesNoData: false, validationType: '', isMandatory: true },
        { key: 'priceFixingDeadlinePriorToDelivery', name: 'NEGOTIATION.TO_BE_FIXED_AT_LEAST', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'priceFixingDeadlinePriorToMATIFExpiration', name: 'NEGOTIATION.DAYS_PRIOR_TO_DELIVERY_AND', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'premiumContractPriceMATIF', name: 'PRICE.FIXING_OF_PREMIUM_CONTRACT_PRICE_ON_MATIF_BY', masterDataKey: 'premiumContractPriceMATIF', type: 'select', isYesNoData: false, validationType: '', isMandatory: true },
        { key: 'premiumContractPriceFixVia', name: 'PRICE.FIXING_VIA', masterDataKey: 'premiumContractPriceFixVia', type: 'select', isYesNoData: false, validationType: '', isMandatory: true },
        { key: 'minimumQuantityPartialFixing', name: 'PRICE.MINIMUM_QUANTITY_PER_PARTIAL_FIXING_IN_METRIC_TONS', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'contractType', type: 'select', name: 'CONTRACT.CONTRACT_TYPE', masterDataKey: 'contractType', isYesNoData: false, validationType: '', isMandatory: true },
        { key: 'differingQuantityChoiceType', name: 'CONTRACT.DIFFERING_QUANTITY', masterDataKey: 'differingQuantityChoiceType', type: 'select', isYesNoData: false, validationType: '', isMandatory: true },
        { key: 'maximumDifferingQuantityAmountType', name: 'CONTRACT.MAX_DIFFERING_QUANTITY_TYPE', masterDataKey: 'maximumDifferingQuantityAmountType', type: 'select', isYesNoData: false, validationType: '', isMandatory: true },
        { key: 'maximumDifferingQuantityAmountValue', name: 'CONTRACT.MAX_DIFFERING_QUANTITY_VALUE', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: true },
        { key: 'termsOfPayment', name: 'CONTRACT.TERMS_OF_PAYMENT', masterDataKey: 'termsOfPayment', type: 'select', isYesNoData: false, validationType: '', isMandatory: true },

        { key: 'formOfPayment', name: 'CONTRACT.FORM_PAYMENT', masterDataKey: 'formsOfPayment', type: 'select', isYesNoData: false, validationType: '', isMandatory: true },
        { key: 'deliveryPeriodType', name: 'KEY_REQUIREMENT.DELIVERY_PERIOD_TYPE', masterDataKey: 'deliveryPeriodType', type: 'select', isYesNoData: false, validationType: '', isMandatory: false },
        { key: 'certificates', name: 'CONTRACT.CERTIFICATION', masterDataKey: 'certification', type: 'multiselect', isYesNoData: false, validationType: '', isMandatory: false },
        { key: 'harvestYear', name: 'QUALITY.YEAR_OF_HARVEST', masterDataKey: 'harvestYearArr', type: 'select', isYesNoData: false, validationType: '', isMandatory: false },
        { key: 'originCountry', name: 'QUALITY.COUNTRY_OF_ORIGIN', masterDataKey: 'country', type: 'select', isYesNoData: false, validationType: '', isMandatory: false },
        { key: 'variety', name: 'QUALITY.VARIETY', masterDataKey: '', type: 'text', isYesNoData: false, validationType: '', isMandatory: false },
        { key: 'originRegion', name: 'QUALITY.REGION_OF_ORIGIN', masterDataKey: '', type: 'text', isYesNoData: false, validationType: '', isMandatory: false },
        { key: 'preservedIdentity', name: 'QUALITY.IDENTITY_PRESERVED', masterDataKey: '', type: 'select', isYesNoData: true, validationType: '', isMandatory: false }, // Type written Dropdown yes / no
        { key: 'dockageMax', name: 'QUALITY.DOCKAGE_MAX', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'moistureMax', name: 'QUALITY.MOISTURE_MAX', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'fallingNoMin', name: 'QUALITY.FALLING_NO_MIN', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'fallingNoMax', name: 'QUALITY.FALLING_NO_MAX', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'wetGlutenMin', name: 'QUALITY.WET_GLUTEN_MIN', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'wetGlutenMax', name: 'QUALITY.WET_GLUTEN_MAX', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'glutenIndexMin', name: 'QUALITY.GLUTEN_INDEX_MIN', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'glutenIndexMax', name: 'QUALITY.GLUTEN_INDEX_MAX', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'brokenOrShrivelledGrainsMax', name: 'QUALITY.BROKEN_GRAINS_MAX', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'fusariumMax', name: 'QUALITY.FUSARIUM_MAX', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        // { key: 'otherGrainsMax', name: 'QUALITY.OTHER_GRAINS_MAX', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'DONMax', name: 'QUALITY.DON_MAX', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'ZEAMax', name: 'QUALITY.ZEA_MAX', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'OTAMax', name: 'QUALITY.OTA_MAX', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'proteinMin', name: 'QUALITY.PROTEIN_MIN', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'proteinMax', name: 'QUALITY.PROTEIN_MAX', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'sedimentationMin', name: 'QUALITY.SEDIMENTATION_MIN', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'ergotMax', name: 'QUALITY.ERGOT', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'sedimentationMax', name: 'QUALITY.SEDIMENTATION_MAX', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'hectoliterMin', name: 'QUALITY.HECTOLITER_MIN', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'huskedYieldMin', name: 'QUALITY.HUSKED_YIELD', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'sproutingMax', name: 'QUALITY.SPROUTING', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'oilContentMin', name: 'QUALITY.OIL_CONTENT', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'freeFattyAcidsMax', name: 'QUALITY.FREE_FATTY_ACIDS', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'erucicAcidMax', name: 'QUALITY.ERUCIC_ACID', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'glucosinolatMax', name: 'QUALITY.GLUCOSINOLAT', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        // { key: 'tradingUnit', name: 'LOGISTIC_HANDLING.TRADING_UNIT', masterDataKey: 'tradingUnit', type: 'select', isYesNoData: false, validationType: '', isMandatory: true },
        { key: 'storageType', name: 'LOGISTIC_HANDLING.STORAGE_TYPE', masterDataKey: 'storageType', type: 'multiselect', isYesNoData: false, validationType: '', isMandatory: false },
        { key: 'unloadingCapacityPerDay', name: 'LOGISTIC_HANDLING.MIN/MAX_UNLOADING_PER_DAY', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'loadingCapacityPerDay', name: 'LOGISTIC_HANDLING.MIN/MAX_LOADING_PER_DAY', masterDataKey: '', type: 'text', isYesNoData: false, validationType: 'number', isMandatory: false },
        { key: 'transportation', name: 'LOGISTIC_HANDLING.TRANSPORTATION_MODE', masterDataKey: 'transportationMode', type: 'multiselect', isYesNoData: false, validationType: '', isMandatory: false },
        { key: 'restDelivery', name: 'LOGISTIC_HANDLING.REST_DELIVERY', masterDataKey: '', type: 'select', isYesNoData: true, validationType: '', isMandatory: false }, // Type written Dropdown yes / no

        { key: 'successiveDelivery', name: 'KEY_REQUIREMENT.SUCCESSIVE_DELIVERY', masterDataKey: '', type: 'select', isYesNoData: true, validationType: '', isMandatory: false }, // Type written Dropdown yes / no

        { key: 'pesticidalAnalysis', name: 'ANALYSIS.PESTICIDAL', masterDataKey: '', type: 'select', isYesNoData: true, validationType: '', isMandatory: false },
        { key: 'mycotoxinAnalysis', name: 'ANALYSIS.MYCOTOXIN', masterDataKey: '', type: 'select', isYesNoData: true, validationType: '', isMandatory: false },
        // { key: 'representativeSample', name: 'ANALYSIS.REPRSENTATIVE', masterDataKey: '', type: 'select', isYesNoData: true, validationType: '', isMandatory: false },
        { key: 'purchaseAfterSampleApproval', name: 'ANALYSIS.PURCHASE', masterDataKey: '', type: 'select', isYesNoData: true, validationType: '', isMandatory: false },
        { key: 'termsOfTradesRadioOption', name: 'TERMS_OF_TRADE.HEADING', masterDataKey: 'termsOfTradesRadioArr', type: 'select', isYesNoData: false, validationType: '', isMandatory: true },
        { key: 'decisiveWeight', name: 'TERMS_OF_TRADE.INCOMING_QUALITIES_WEIGHT_DECISIVE', masterDataKey: '', type: 'select', isYesNoData: true, validationType: '', isMandatory: false },
        { key: 'excludedSewageSludge', name: 'TERMS_OF_TRADE.SEWAGE_SLUDGE_FERTILIZATION_EXCLUDED', masterDataKey: '', type: 'select', isYesNoData: true, validationType: '', isMandatory: false },
        { key: 'termsOfTradesQualityMeasurement', name: 'TERMS_OF_TRADE.QUALITY_ACCOUNTING', masterDataKey: 'termsOfTradesQualityMeasurementArr', type: 'select', isYesNoData: false, validationType: '', isMandatory: true },
        // { key: 'customTerms', name: 'TERMS_OF_TRADE.CUSTOM_TERMS', masterDataKey: '', type: 'select', isYesNoData: true, validationType: '', isMandatory: false },
    ];

    public static readonly deactivateArr = [
        { id: '1045', name: 'DEACTIVATEARRAYVAL.TOMORROW_10_45_AM' },
        { id: '24H', name: 'DEACTIVATEARRAYVAL.24_HOURS' },
        { id: '2D', name: 'DEACTIVATEARRAYVAL.2_DAYS' },
        { id: '3D', name: 'DEACTIVATEARRAYVAL.3_DAYS' },
        { id: '4D', name: 'DEACTIVATEARRAYVAL.4_DAYS' },
        { id: '5D', name: 'DEACTIVATEARRAYVAL.5_DAYS' },
        { id: '6D', name: 'DEACTIVATEARRAYVAL.6_DAYS' },
        { id: '1W', name: 'DEACTIVATEARRAYVAL.1_WEEK' },
        { id: '2W', name: 'DEACTIVATEARRAYVAL.2_WEEKS' },
        { id: '3W', name: 'DEACTIVATEARRAYVAL.3_WEEKS' },
        { id: '1M', name: 'DEACTIVATEARRAYVAL.1_MONTH' },
        { id: '2M', name: 'DEACTIVATEARRAYVAL.2_MONTHS' },
        { id: '3M', name: 'DEACTIVATEARRAYVAL.3_MONTHS' },
        { id: '6M', name: 'DEACTIVATEARRAYVAL.6_MONTHS' }
    ];

    public static readonly priceTypeDropDown = [
        { value: 'setInNegotiation', name: 'PRICE.SET_PRICE_IN_NEGOTIATION', active: true },
        { value: 'flatePrice', name: 'PRICE.FALT_PRICE', active: true },
        { value: 'relativeToMATIF', name: 'PRICE.FLAT_RELATIVE_TO_MATIF', active: true },
        { value: 'PremiumPrice', name: 'PRICE.PREMIUM_PRICE_MATIF', active: true }
    ];

    public static readonly demandOfferAllKeysMasterJSON = {
        isBinding: { isVisible: true, isEditable: true },
        partialQuantity: { isVisible: true, isEditable: true },
        bindingDeactivateData: { isVisible: true, isEditable: true },
        bindDeactivateDate: { isVisible: true, isEditable: true },
        bindDeactivateTime: { isVisible: true, isEditable: true },
        customName: { isVisible: true, isEditable: true },
        entity: { isVisible: true, isEditable: true },
        vaildTill: { isVisible: true, isEditable: true },
        deactivateData: { isVisible: true, isEditable: true },
        storageFee: { isVisible: true, isEditable: true },
        storageFeePeriod: { isVisible: true, isEditable: true },
        storageFeeStartDate: { isVisible: true, isEditable: true },
        successiveDelivery: { isVisible: true, isEditable: true },
        baseMonth: { isVisible: true, isEditable: true },
        deliveryPeriodType: { isVisible: false, isEditable: false },
        productType: { isVisible: true, isEditable: true },
        qualityClass: { isVisible: true, isEditable: true },
        parity: { isVisible: true, isEditable: true },
        quantity: { isVisible: true, isEditable: true },
        deliveryPeriod: { isVisible: true, isEditable: true },
        deliveryPeriodStartDate: { isVisible: true, isEditable: true },
        deliveryPeriodEndDate: { isVisible: true, isEditable: true },
        deliveryPeriodWithoutGrace: { isVisible: true, isEditable: true },
        priceType: { isVisible: true, isEditable: true },
        flatPriceType: { isVisible: true, isEditable: true },
        flatPriceValue: { isVisible: true, isEditable: true },
        flatPriceValue1: { isVisible: true, isEditable: true },
        premiumPriceEURPerMetricTon: { isVisible: true, isEditable: true },
        premiumPriceForwardBaseMATIF: { isVisible: true, isEditable: true },
        premiumPriceFixingTimeDate: { isVisible: true, isEditable: true },
        premiumPriceFixingTime: { isVisible: true, isEditable: true },
        premiumPriceFixingTimeStartDate: { isVisible: true, isEditable: true },
        premiumPriceFixingTimeEndDate: { isVisible: true, isEditable: true },
        priceFixingDeadlinePriorToDelivery: { isVisible: true, isEditable: true },
        priceFixingDeadlinePriorToMATIFExpiration: { isVisible: true, isEditable: true },
        premiumContractPriceMATIF: { isVisible: true, isEditable: true },
        premiumContractPriceFixVia: { isVisible: true, isEditable: true },
        minimumQuantityPartialFixing: { isVisible: true, isEditable: true },
        contractType: { isVisible: true, isEditable: true },
        differingQuantityChoiceType: { isVisible: true, isEditable: true },
        maximumDifferingQuantityAmountType: { isVisible: true, isEditable: true },
        maximumDifferingQuantityAmountValue: { isVisible: true, isEditable: true },
        termsOfPayment: { isVisible: true, isEditable: true },
        formOfPayment: { isVisible: true, isEditable: true },
        certificatesNeed: { isVisible: true, isEditable: true },
        certificates: { isVisible: true, isEditable: true },
        paymentDocument: { isVisible: true, isEditable: true },
        harvestYear: { isVisible: true, isEditable: true },
        originCountry: { isVisible: true, isEditable: true },
        originRegion: { isVisible: true, isEditable: true },
        variety: { isVisible: true, isEditable: true },
        preservedIdentity: { isVisible: true, isEditable: true },
        dockageMax: { isVisible: true, isEditable: true },
        moistureMax: { isVisible: true, isEditable: true },
        proteinMin: { isVisible: true, isEditable: true },
        proteinMax: { isVisible: true, isEditable: true },
        sedimentationMin: { isVisible: true, isEditable: true },
        sedimentationMax: { isVisible: true, isEditable: true },
        fallingNoMin: { isVisible: true, isEditable: true },
        fallingNoMax: { isVisible: true, isEditable: true },
        hectoliterMin: { isVisible: true, isEditable: true },
        wetGlutenMin: { isVisible: true, isEditable: true },
        wetGlutenMax: { isVisible: true, isEditable: true },
        glutenIndexMin: { isVisible: true, isEditable: true },
        glutenIndexMax: { isVisible: true, isEditable: true },
        brokenOrShrivelledGrainsMax: { isVisible: true, isEditable: true },
        fusariumMax: { isVisible: true, isEditable: true },
        DONMax: { isVisible: true, isEditable: true },
        ZEAMax: { isVisible: true, isEditable: true },
        OTAMax: { isVisible: true, isEditable: true },
        ergotMax: { isVisible: true, isEditable: true },
        huskedYieldMin: { isVisible: true, isEditable: true },
        sproutingMax: { isVisible: true, isEditable: true },
        oilContentMin: { isVisible: true, isEditable: true },
        freeFattyAcidsMax: { isVisible: true, isEditable: true },
        erucicAcidMax: { isVisible: true, isEditable: true },
        glucosinolatMax: { isVisible: true, isEditable: true },
        placeOfFulfillment: { isVisible: true, isEditable: true },
        storageType: { isVisible: true, isEditable: true },
        unloadingCapacityPerDay: { isVisible: true, isEditable: true }, // Demand Only 
        transportation: { isVisible: true, isEditable: true }, // Demand Only
        possibleVehicleUnloading: { isVisible: true, isEditable: true },
        logisticReturnTrip: { isVisible: true, isEditable: true },
        returnTripStartingPoint: { isVisible: true, isEditable: true },
        pesticidalAnalysis: { isVisible: true, isEditable: true },
        mycotoxinAnalysis: { isVisible: true, isEditable: true },
        purchaseAfterSampleApproval: { isVisible: true, isEditable: true },
        termsOfTradesRadioOption: { isVisible: true, isEditable: true },
        decisiveWeight: { isVisible: true, isEditable: true },
        excludedSewageSludge: { isVisible: true, isEditable: true },
        termsOfTradesQualityMeasurement: { isVisible: true, isEditable: true },
        customTerms: { isVisible: true, isEditable: true },
        additionalNotes: { isVisible: true, isEditable: true },
        attachments: { isVisible: true, isEditable: true },
        templateId: { isVisible: true, isEditable: true },
        approachedCompanies: { isVisible: true, isEditable: true },
        view: { isVisible: true, isEditable: true },
        publishType: { isVisible: true, isEditable: true },
        restDelivery: { isVisible: true, isEditable: true }, // offer only
        loadingCapacityPerDay: { isVisible: true, isEditable: true }, // offer only
        possibleVehicleLoading: { isVisible: true, isEditable: true }, // offer only
    };

    public static readonly companyIntroJSContent = {
        // introduction: {
        //     en: '<iframe width="560" height="315" src="https://www.youtube.com/embed/yH3G9QgrBfs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></br>"<b>Welcome</b> to House of Crops Unamera", please follow this guide to complete your <a href="http://www.houseofcrops.de" target="_blank">registration</a>.',
        //     de: '"Welcome to House of Crops Unamera", please follow this guide to complete your <a href="www.houseofcrops.de" target="_blank">registration</a>.'
        // },
        introduction: {
            en: 'Welcome to House of Crops unamera, please follow this guide to complete your registration.',
            de: 'Herzlich willkommen bei House of Crops Unamera. Bitte folgen Sie den Schritten, um Ihr Konto vollständig anzulegen.'
        },
        step1: {
            en: 'Please click the logo field in order to upload a logo of your company. Alternatively,  you can skip this step.',
            de: 'Hier haben Sie die Option Ihr firmeneigenes Logo hinzuzufügen. Alternativ können Sie diesen Schritt überspringen.'
        },
        step2: {
            en: 'By selecting this checkbox you enable potential trading partners to send you offers directly. This can be disabled at any time via your account preferences.',
            de: 'Mit Auswahl dieser Checkbox, können potentielle Handelspartner Ihnen Geschäfte direkt anbieten. Diese Option können Sie nachträglich in den Einstellungen ändern.'
        },
        step3: {
            en: 'Please click continue. You are able to change any of the settings at any time, after you have completed your registration.',
            de: 'Bitte klicken Sie auf fortfahren. Sie können die Einstellungen jederzeit ändern, nachdem Sie Ihre Registrierung abgeschlossen haben.'
        },

        // === Entity page started ===
        step4: {
            en: 'Please complete information about your main business entity in the following steps. If applicable, you can later add more entities to your account.',
            de: 'Bitte vervollständigen Sie in den folgenden Schritten Angaben zu Ihrem Hauptbetrieb. Bei Bedarf können Sie anschließend beliebig viele Betriebe zu Ihrem Konto hinzufügen.'
        },

        step5: {
            en: 'Please enter the tax id of your business entity.',
            de: 'Bitte geben Sie hier die Umsatzsteuer-ID des Betriebes an (falls nicht vorhanden bitte Steuernummer angeben).'
        },
        step6: {
            en: 'Please accept the terms of use by selecting the checkbox.',
            de: 'Bitte akzeptieren Sie die Nutzungsbedingungen in dem Sie die Checkbox auswählen.'
        },
        step7: {
            en: 'Please select a type for your entity',
            de: 'Bitte wählen Sie einen Unternehmenstyp für Ihren Betrieb aus.'
        },
        step8: {
            en: 'Please select "Yes" from the dropdown if you file composite tax returns, otherwise select "No".',
            de: 'Bitte wählen Sie "Ja" im Menü, wenn Sie Pauschalierer sind. Ansonsten wählen Sie bitte "Nein"'
        },
        step9: {
            en: 'Please select your certifications. If you have none, please click on Skip.',
            de: 'Bitte wählen Sie Ihre Zertifizierungen aus. Falls keine vorhanden sind, klicken Sie auf Überspringen.'
        },
        step10: {
            en: 'If you have additional entity locations, please enter these here. Alternatively click skip.',
            de: 'Falls Sie über weitere Betriebsstandorte verfügen, geben Sie diese bitte hier ein. Alternativ klicken Sie auf Überspringen.'
        },
        step11: {
            en: 'Please enter the bank account information of your company here. These will only be transmitted to your trading partner for payment processing in case of a contract conclusion. By specifying the bank account, no direct debit authorization is granted.',
            de: 'Bitte geben Sie hier die Bankkontoinformationen Ihres Betriebes an. Diese werden ausschließlich im Falle eines Kontraktschlusses Ihrem Handelspartner zur Zahlungsabwicklung übermittelt. Durch Angabe des Bankkontos wird keine Einzugsermächtigung erteilt.'
        },
        step12: {
            en: 'If there are companies you do not wish to enter into a negotiation with, please add these by clicking “Set Blacklist” and choosing to save after you have entered the data. If you do not wish to add companies to the blacklist right now, choose “Skip” below.',
            de: 'Sollte es Unternehmen geben, mit denen Sie nicht in Verhandlung treten möchten, fügen Sie diese bitte hinzu, indem Sie auf “Blacklist Festlegen” klicken und nach Abschluss der Dateneingabe “Speichern” wählen. Wenn Sie jetzt keine Unternehmen zu der Blacklist hinzufügen möchten, wählen Sie “Überspringen”.'
        },
        step13: {
            en: 'Thank you for entering your information. Please click on “Continue” to complete your registration.',
            de: 'Vielen Dank für die Angabe Ihrer Informationen. Klicken Sie auf “Fortfahren” um Ihre Registrierung abzuschließen.'
        },
        tradingProfileContent: {
            en: 'Tell us which lots you are interested in trading! Our digital assistant will scan the market for attractive trading opportunities for you. If you are only a buyer, simply leave the fields in the “Seller” tab blank and click Next to fill out the “Buying” tab.',
            de: 'Sagen Sie uns, welche Partien Sie zum Handeln interessieren! Unser digitaler Assistent scannt für Sie den Markt nach attraktiven Handelsmöglichkeiten. Wenn Sie nur Käufer sind, lassen Sie die Felder im “Verkäufer” Reiter einfach frei und klicken Sie auf Weiter um den “Käufer” Reiter auszufüllen.'
        }
    };
}
