import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConst } from 'src/app/app.constant';

@Injectable({
    providedIn: 'root'
})
export class CommonApiService {

    constructor(
        private httpClient: HttpClient
    ) { }

    /**
    * Function To get all country list Data
    *
    * @returns {Observable<any>}
    * @memberof CommonApiService
    */
    getCountryList(): Observable<any> {
        const request = { 'url': AppConst.API_BASE_URL + 'v1/master/countryCallingCode' };
        return this.httpClient.get<any>(request.url);
    }

    /**
    * Method is responsible for fetching demand master data
    *
    * @returns {Observable<any>}
    * @memberof CommonApiService
    */
    getDemandMasterData(userId, marketPlaceId = '', marketPlaceTemplateId = '', demandId = ''): Observable<any> {
        const request = { 'url': AppConst.API_BASE_URL + 'v1/master/demand/' + userId + '/?marketPlaceId=' + marketPlaceId + '&marketPlaceTemplateId=' + marketPlaceTemplateId + '&demandId=' + demandId };
        // const request = { 'url': '/assets/demand.json' };
        return this.httpClient.get<any>(request.url);
    }

    /**
    * Method is responsible for fetching demand filter master data
    *
    * @returns {Observable<any>}
    * @memberof CommonApiService
    */
    getOfferDemandFilterMasterData(): Observable<any> {
        const request = { 'url': AppConst.API_BASE_URL + 'v1/master/offerDemandFilter' };
        // const request = { 'url': '/assets/demand.json' };
        return this.httpClient.get<any>(request.url);
    }

    /**
    * Method is responsible for fetching qualities
    *
    * @returns {Observable<any>}
    * @memberof CommonApiService
    */
    getQualities(productList): Observable<any> {
        const request = {
            'url': AppConst.API_BASE_URL + 'v1/master/getQualities',
            'params': productList
        };
        return this.httpClient.post<any>(request.url, request.params);
    }

    /**
   * Method is responsible for fetching offer master data
   *
   * @returns {Observable<any>}
   * @memberof CommonApiService
   */
    getOfferMasterData(userId, marketPlaceId = '', marketPlaceTemplateId = '', offerId = ''): Observable<any> {
        const request = { 'url': AppConst.API_BASE_URL + 'v1/master/offer/' + userId + '/?marketPlaceId=' + marketPlaceId + '&marketPlaceTemplateId=' + marketPlaceTemplateId + '&offerId=' + offerId };
        // const request = { 'url': '/assets/demand.json' };
        return this.httpClient.get<any>(request.url);
    }

    /**
    * Method is responsible for fetching demand master data
    *
    * @returns {Observable<any>}
    * @memberof CommonApiService
    */
    getQualityClassData(productId): Observable<any> {
        const request = { 'url': AppConst.API_BASE_URL + 'v1/master/quality/' + productId };
        return this.httpClient.get<any>(request.url);
    }


    /**
    * Method is responsible for getting onboarding status
    *
    * @returns {Observable<any>}
    * @memberof CommonApiService
    */
    getOnboardingStatus(): Observable<any> {
        const request = { 'url': AppConst.API_BASE_URL + 'v1/user/onboardingStatus' };
        return this.httpClient.get<any>(request.url);
    }

    /**
    * Method is responsible for fetching terms of trade list
    *
    * @returns {Observable<any>}
    * @memberof CommonApiService
    */
    getTermsOfTradeList(productId): Observable<any> {
        const request = { 'url': AppConst.API_BASE_URL + 'v1/master/termsOfTrade/' + productId };
        return this.httpClient.get<any>(request.url);
    }

    /**
    * Method is responsible for fetching forwardBase list product list
    *
    * @returns {Observable<any>}
    * @memberof CommonApiService
    */
    getforwardBaseList(productId): Observable<any> {
        const request = { 'url': AppConst.API_BASE_URL + 'v1/master/forwardBase/' + productId };
        return this.httpClient.get<any>(request.url);
    }
}
